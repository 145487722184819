<template>
    <Toast/>
    <div class="surface-0 flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden">
        <div class="grid justify-content-center p-2 lg:p-0" style="min-width:80%">
            <div class="col-12 xl:col-6" style="border-radius:56px; padding:0.3rem; background: linear-gradient(180deg, var(--primary-color), rgba(33, 150, 243, 0) 30%);">
                <div class="h-full w-full m-0 py-7 px-4" style="border-radius:53px; background: linear-gradient(180deg, var(--surface-50) 38.9%, var(--surface-0));">
                    <form>
                        <div class="w-full md:w-10 mx-auto">
                            <label for="email1" class="block text-900 text-xl font-medium mb-2">Email</label>
                            <InputText id="email1" v-model="email" type="text" class="w-full mb-3" placeholder="Email" style="padding:1rem;" />

                            <label for="password1" class="block text-900 font-medium text-xl mb-2">Jelszó</label>
                            <Password id="password1" v-model="password" placeholder="Password" :toggleMask="true"
                                      class="w-full mb-3" inputClass="w-full" inputStyle="padding:1rem" :feedback="false"/>

                            <Button type="button" label="Bejelentkezés" class="w-full p-3 text-xl"
                                    :icon="icon" :disabled="loading" :loading="loading" @click="login"></button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    data() {
        return {
            email: '',
            password: '',
            icon: 'pi pi-sign-in',
            loading: false,
        }
    },
    methods: {
        login() {
            this.loading = true;

            let user = {
                email: this.email,
                password: this.password,
            }

            this.$store.dispatch("auth/logIn", user).then(
                () => {
                    this.$router.push("/events");
                },
                (error) => {
                    this.loading = false;

                    this.$toast.add({
                        severity: "error",
                        summary: "Sikertelen bejelentkezés!",
                        detail: error.response.data.error,
                        life: 5000,
                    });
                }
            );
        },
    },
    computed: {
        loggedIn() {
            return this.$store.state.auth.status.loggedIn;
        },
    },
    created() {
        if (this.loggedIn) {
            this.$router.push("/events");
        }
    },
}
</script>

<style scoped>
.pi-eye {
    transform:scale(1.6);
    margin-right: 1rem;
}

.pi-eye-slash {
    transform:scale(1.6);
    margin-right: 1rem;
}
</style>